import React from "react";
import '../App.css';
import logo from '../assets/main.jpg'
import { Link } from "react-router-dom";

function Legal() {
    return (
        <>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="Auto Dealership Fraud" />
        <meta name="author" content="" />
        <title>Findlay Chrysler Dodge Jeep Ram Auto Dealer Fraud | Car Dealer in Findlay, OH</title>
        
        <link rel="icon" type="image/x-icon" href="assets/favicon.ico" />
       
        <link href="css/styles.css" rel="stylesheet" />
      
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container">
            <a className="navbar-brand text-center text-uppercase fw-bolder">
            <Link to="/">FINDLAYCRYSLERS.COM</Link>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link active text-uppercase fw-bolder"
                    aria-current="page"
                    href="https://findlaychryslers.com/"
                  >
                    <Link to="/">Home</Link>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active text-uppercase fw-bolder"
                    href="https://www.ohioattorneygeneral.gov/individuals-and-families/consumers/file-a-complaint.aspx" target="_blank"
                  >
                    Report
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active text-uppercase fw-bolder"
                    href="legal"
                  >
                    <Link to="/Legal">Legal</Link>
                  </a>
                </li>
                <li className="nav-item dropdown"></li>
              </ul>
            </div>
          </div>
        </nav>
        {/* Page content*/}
        <div className="container">
          <div className="text-center mt-5 text-uppercase">
            <h3>
              <b><title>Findlay Chrysler Dodge Jeep Ram Auto Dealership Fraud</title></b>
            </h3>
          </div>
        </div>
        <div className="container">
          <div className="text-center mt-5 mb-5 text-uppercase">
            <p className="lead">
              <b>Kable Derrows Automotive Used Car Sales Fraud</b>
            </p>
            <a
              href="https://www.findlaychrysler.com/?utm_source=gmb&utm_medium=organic&utm_campaign=gmb"
              target="_blank"
            >
              Findlay Chrystler located at: 10305 U.S. 224 West, Findlay, OH 45840
            </a>
          </div>
        </div>
        <br />
        <container>
          <img src={logo} 
            style={{ padding: "0px 30px 0px 30px", maxWidth: 1200 }}
            className="rounded mx-auto d-block w-100"
            alt="findlay automotive auto scam"
          />
        </container>
        <div className="container py-5">
          <span>
            <p> </p>
            <p className="c3">
              <span className="c0">
                The information provided on this website is factual, documented and
                there is considerable, credible evidence against Derrow Automotive its
                owners and associates for these actions. By Creating a report and
                supporting our case you're helping your representatives know Findlay
                is responsible. Through this we're able to gain additional support in
                our effort to bring Findlay and its ownership to court. We seek
                justice for those who have been wronged by the auto industry. Findlay
                Automotive is a partner of Treadway Chrysler-Dodge-Jeep, Inc. In no
                way do I misinterpret or provide misleading details in these
                statements. All statements are accurate including details of the
                staff, dates and times from my accounts.{" "}
              </span>
            </p>
            <p className="c3">
              <span>
                If you or someone you know has had a similar issue reach out to the
                Ohio Attorney General's Office here:{" "}
              </span>
              <span className="c1">
                <a
                  className="c5"
                  href="https://www.google.com/url?q=https://www.ohioattorneygeneral.gov/&sa=D&source=editors&ust=1651038895961574&usg=AOvVaw0Y5Zcx9YxKwnJ6_0mT-ugo"
                >
                  https://www.ohioattorneygeneral.gov/
                </a>
              </span>
              <span>
                &nbsp;Mr. Yost’s office is very quick and the staff is friendly. In
                addition to this I would recommend submitting a formal complaint with
                the Attorney General's Office which can be done online here:{" "}
              </span>
              <span className="c1">
                <a
                  className="c5"
                  href="https://www.google.com/url?q=https://www.ohioattorneygeneral.gov/individuals-and-families/consumers/file-a-complaint.aspx&sa=D&source=editors&ust=1651038895961905&usg=AOvVaw26jnrZ9_GifCO5GOVFAvVk"
                >
                  https://www.ohioattorneygeneral.gov/individuals-and-families/consumers/file-a-complaint.aspx
                </a>
              </span>
              <span className="c0">&nbsp;</span>
            </p>
            <p className="c3 c4">
              <span className="c0" />
            </p>
            <p className="c3">
              <span className="c0">
                Finally I would also recommend creating a report with the Ohio DMV
                Commercial Licensing division. Findlay Chryslers Dealer Permit # is:
                ND003805 when a complaint is filed provide this permit number and be
                as detailed as possible.{" "}
              </span>
            </p>
            <p className="c3 c4">
              <span className="c0" />
            </p>
            <p className="c3">
              <span>
                If you would like to submit an email in regards to this matter please
                contact:{" "}
              </span>
              <span className="c1">
                <a className="c5" href="mailto:info@vortexx.co">
                  legal@vortexx.co
                </a>
              </span>
              <span className="c0">&nbsp;</span>
            </p>
            <p className="c3 c4">
              <span className="c0" />
            </p>
            <p className="c3 c4">
              <span className="c0" />
            </p>
            <p />
            <br />
            <div className="container text-center d-grid  mx-auto m-2">
              <a
                href="https://www.ohioattorneygeneral.gov/individuals-and-families/consumers/file-a-complaint.aspx"
                target="blank"
                className="btn btn-primary active"
                type="button"
                id="cc"
                style={{ backgroundColor: "#0377fc !important" }}
              >
                SUPPORT US BY FILING A REPORT
              </a>
            </div>
           
          </span>
        </div>
      </>
      
    );
    }
    
    export default Legal;
    