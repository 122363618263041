import React from "react";
import '../App.css';
import logo from '../assets/main.jpg'
import { Link } from "react-router-dom";



function Home() {
    return (
        <>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="Auto Dealership Fraud" />
        <meta name="author" content="" />
        <title>Findlay Chrysler Dodge Jeep Ram Auto Dealership Fraud | Car Dealer in Findlay, OH</title>
       
        <link rel="icon" type="image/x-icon" href="assets/favicon.ico" />
      
        <link href="css/styles.css" rel="stylesheet" />
       
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container">
            <a className="navbar-brand text-center text-uppercase fw-bolder">
             <Link to="/">FINDLAYCRYSLERS.COM</Link>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link active text-uppercase fw-bolder"
                    aria-current="page"
                    
                  >
                    <Link to="/">Home</Link>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active text-uppercase fw-bolder"
                    href="https://www.ohioattorneygeneral.gov/individuals-and-families/consumers/file-a-complaint.aspx" target="_blank"
                  >
                    Report
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active text-uppercase fw-bolder"
                    
                  >
                    <Link to="/Legal">Legal</Link>
                  </a>
                </li>
                <li className="nav-item dropdown"></li>
              </ul>
            </div>
          </div>
        </nav>
        {/* Page content*/}
        <div className="container">
          <div className="container">
            <div className="text-center mt-5 text-uppercase">
              <h3>
              <title>Findlay Chrysler Dodge Jeep Ram Auto Dealership Fraud | Car Dealer in Findlay, OH</title>
              </h3>
            </div>
          </div>
          <div className="container">
            <div className="text-center mt-5 mb-5 text-uppercase">
              <p className="lead">
                <b>Kable Derrows Automotive Used Car Sales Fraud</b>
              </p>
              <a
                href="https://www.findlaychrysler.com/?utm_source=gmb&utm_medium=organic&utm_campaign=gmb"
                target="_blank"
              >
                Findlay Chrystler located at: 10305 U.S. 224 West, Findlay, OH 45840
              </a>
            </div>
          </div>
          <br />
          <container>
            <img src={logo}
              style={{ padding: "0px 30px 0px 30px", maxWidth: 1200 }}
              className="rounded mx-auto d-block w-100"
              alt="findlay automotive auto scam"
            />
          </container>
          <div className="container py-5">
            <span></span>
          </div>
          <div className="container">
            <div className="text-left">
              <p>
                In October 2021 I purchased a Jeep Grand Cherokee from Findlay
                Chrysler that would ultimately end up costing me thousands given there
                were numerous components and parts having pre-existing damage. The
                vehicle sold to me had extensive pre-existing damage given it was an
                auctioned vehicle which was never at any point in time ever brought to
                my attention until I discussed it just before signing for the vehicle.
                Rick Duffman the sales associate who sold the vehicle to me stated the
                vehicle was owned by a female when again it came from an auction and
                was previously owned by a male, two lies right off the bat. The
                vehicle had numerous broken external, internal and electrical
                components that required replacing. These details were covered up
                prior to sale but I was verbally told everything worked and was fine
                even being shown an iPad which they do with all their customers to
                cover up the actual truth during the signing. Findlay doesn't provide
                its customers with any type of certification statement during the
                signing either leaving them a major loop hole to use against the
                consumer. Findlay also uses an AS-IS agreement using Ohio law against
                the consumer.
              </p>
              <p>
                Findlay sales representatives intentionally misinterpret, deceive and
                lie to their customers through various means to bring in a bigger
                close. Findlay is the perfect example of a dealership that will go to
                almost any length to make a sale and make income no matter the cost
                even if it means te customers safety and or well being. Findlay
                recently fired the GM that was responsible for the sale of my vehicle
                which is no suprise. He then began working for Honda of Cleveland
                Heights where he was again fired just recently.{" "}  
                <a
                  href="https://www.linkedin.com/in/david-siebenaller-7a309a47/"
                  target="blank"
                >
                    Click Here
                </a>{" "}
                Mr. Siebenaller now works for a group called Credit Acceptance
                where he boasts on his LinkedIn his incredible achievements as a Market Area Manager. 
                Individuals like this run Findlay and Findlay will find any
                method they can to again make a profit over the consumer. Findlay is
                run and operated by associates and managers that are classless,
                unethical and immoral.
              </p>
              <p>
                Being ethical and honest with the consumer is important and you
                shouldn’t have to go through difficulties or feel that you’re going to
                be misled while purchasing a vehicle. Everyday dealerships like
                Findlay Automotive take advantage of the consumer by using aggressive
                sales tactics and deceptive behavior. These types of actions must
                stop. There are a lot of desperate and unethical individuals that take
                from others for their own betterment and this describes many
                individuals who work for and operate Findlay Chrysler based in
                Findlay, OH. The owners of Findlay Chrysler, Kable Derrow and other
                members are fully aware of these practices and actions and they allow
                them so they can have better overall profit. Derrow Automotive has a
                background of this type of behavior which can be seen from their
                reviews and through extensive research I have found and obtained
                online public records supporting these issues. Under Derrow Automotive
                Defiance LLC, Mr. Derrow has reported major recent profits exceeding
                most used car dealers. Lloyd Treadway who is also associated and holds
                ownership under a separate LLC titled Treadway Chrysler-Dodge-Jeep,
                Inc reported even higher figures last year. Derrow automotive is
                purchasing used, damaged vehicles throwing them on the sales floor,
                telling customers they are in great condition leading
                the customer into a fraudulent sale.
              </p>
              <p>
                The auto industry deserves better and Findlay Automotive needs to be
                held accountable. Findlay Chrysler needs to be held lawfully liable
                for these types of recurring events. Support me by filing with the
                state attorney by clicking the link below.
              </p>
              <div className="container text-center d-grid gap-2 mx-auto m-5">
                <a
                  href="https://www.ohioattorneygeneral.gov/individuals-and-families/consumers/file-a-complaint.aspx"
                  target="blank"
                  className="btn btn-primary active"
                  type="button"
                  id="cc"
                  style={{ backgroundColor: "#0377fc !important" }}
                >
                  SUPPORT US BY FILING A REPORT
                </a>
              </div>
              <p>
                The purpose of this webpage is to outline the attempted fraud, illegal
                sales practices and behaviors that have and are taking place at Mr.
                Derrows establishment(s). This information is shared with federal and
                state prosecutors, county commissions and other outside organizations
                such as the FTC and CPA (Consumer Protection Agency) for further
                investigation. Currently consumers are faced with sales practices and
                methods that are not in any way legal and are explicitly prohibited by
                both state, federal and consumer guidelines. My ultimate goal while
                continuing to work with authorities is to impose a lawsuit and have a
                hearing in Hancock County in Common Plea court holding this dealership
                liable. Initial documents and details support the means of a trial but
                I have been successful in contacting other consumers who have worked
                with Findlay Automotive where a class-action lawsuit does have
                potential.
              </p>
              <p>
                Derrow Automotive has violated several consumer sales acts including
                Ohio state fraud statutes. Not only during my specific sales
                transaction but other individuals have dealt with similar issues as
                well which are recorded through Google Reviews for the business. In
                the Sales Agreement Derrow Automotive sales associates attempted to
                withhold funds during my initial contract signing in the total amount
                of $4,000 USD. My initial trade-in value for my vehicle was estimated
                to be $29,000 which was agreed upon prior and was shown to me and
                verbal confirmation was provided by both Rick Duffman and Jeremy
                Chetister. Mr. Chetister you can read more about him here:{" "}
                <a
                  href="https://www.linkedin.com/in/jeremy-chetister-60245a262/"
                  target="blank"
                >
                  Click Here
                </a>{" "}
                After Mr. Chetister was caught he deleted his last LinkedIn account page, changed his current LinkedIn to say 
                'Be Kind' which we thought was comical. The allowance based on my vehicle's pay-off was discussed three
                different times prior to any contract being printed or modified and
                Mr. Chetister assured me all electronics 'including the navigation'
                were fully functional. I also showed Rick Duffman my payoff
                information on my mobile phone confirming the amount for the trade-in
                which again was verified.
              </p>
              <p>
                On the day of sale I worked with multiple individuals including: Rick
                Duffman, David Siebenaller, Brad Overly, Jeremy Chetister and Amber
                Johnson. Several other individuals were also involved in the process
                but not directly with my sale. These individuals lied, manipulated the
                sale process and sold me a vehicle that was without question
                defective. Management would later completely deny these claims. Amber
                Johnson, the female sales associate who acted as a 'finance director'
                that afternoon would assist in the fraudulent transaction and
                withholding of the $4,000 from my trade-in allowance. Rick Duffman,
                David Siebenaller, Brad Overly and Jeremy Chetister all were involved
                in the process and each were fully aware of these false and deceptive
                documents. The actual Bill of Sale including the trade-in allowance
                for my vehicle was placed in the second to last page of all the
                documents provided to me during the signing. Again the signing was
                planned and instigated at a very specific point in time where I would
                not notice these changes so they could keep an additional $4,000.
              </p>
              <p>
                While transferring my items into the new vehicle Rick Duffman
                proceeded to ask me to step in to begin the signing. At that exact
                moment I was still speaking to someone on the phone and we quickly
                started going through the paperwork. Once all the documents were
                signed which only took 4-5 minutes Amber Johnson stood up and abruptly
                shuffled my papers into an envelope while I was still on my phone and
                handed them to me all within about ten to fifteen seconds after
                signing the final page. Ms. Johnson was basically walking away before
                I could even ask any questions so she could go and finalize the
                documents which was fraud. I prompted her to wait a moment as I had a
                question. I then pulled out several papers as I wanted confirm the
                total amount on the Bill of Sale and to no surprise my total amount
                for my trade-in allowance within the last line item was shorted the
                4,000.
              </p>
              <p>
                At this moment I should have stopped, contacted the local sheriff's
                office and immediately filed a police report knowing what just took
                place. Prior to the written signing again I was shown a tablet by Mr.
                Duffman where it showed the vehicles cost with tax and the price was
                verbally confirmed where we both verbally agreed. Going back to the
                signing The Bill of Sale which is the first document a dealer should
                show you was placed again at the very end of the set of documents. I
                was never asked who I wanted to finance with or given any options for
                a bank of my choosing and for someone who just drove five hours away
                they conveniently had dirty floorboards and trash from one of the
                managers supposedly driving it to work that morning? After signing and
                leaving the dealership I decided to drive and go get the vehicle
                inspected before leaving the state. Initially when I came up the day
                prior I was unable to purchase the vehicle given how late it was.
                After the signing at approximately 12:35PM I contacted Mr. Duffman and
                drove back to the dealership. I called explaining how one of the
                buttons on the steering column would not work. Little did I know I
                just diagnosed additional damage as the ACC module in the vehicle was
                defective. I thought this was simply a vehicle setting that needed to
                be adjusted which was not the case. This part alone ended up costing
                over $2,000 to fix.
              </p>
              <p>
                Prior to the signing I indicated to Mr. Chetister ‘If there was
                something blatantly wrong with the vehicle I needed to know’ after
                becoming a bit frustrated and noticeably nervous he replied ‘well I
                just don’t think I want to sell you the vehicle, you’re going to make
                a big deal out of nothing.’ This is when it was very apparent
                something was being hidden but I wasn’t sure what. I mentioned legal
                ramifications and it was apparent he was becoming even more nervous.
                The iPad Mr. Chetister provided to me prior to sale had inaccurate,
                intentional misinformation and details related to the vehicle and its
                condition. This is why it was given to me prior to any sales contract
                or documents. He would later go on to state how ‘I’ve seen crazy
                things where people blow an engine rod while going home’ and he starts
                discussing how if there's an issue with the vehicle I had no recourse.
                Mr. Chetister starts to then discuss the vehicle's carfax report and
                what things will cost. He shows a vehicle certification condition
                statement verifying that the vehicle has been fully inspected and that
                everything was functioning correctly with flying colors.
              </p>
              <p>
                Through the entire buying process I was cordial, polite and was
                willing to work with everyone at Findlay but it didn’t take long to
                figure out management was looking to do more than just sell a vehicle.
                Ohio State law allows for auto dealerships to use AS-IS agreements and
                it becomes difficult for consumers to protect themselves. This is one
                way dealerships take advantage of the consumer and blur the lines to
                confuse and manipulate consumers. At the end of the day get your
                information in writing and always require a private third-party
                inspection of the vehicle prior to your final purchase. I own several
                other vehicles personally and at the time I didn’t question the
                condition of the vehicle internally given the dash checked out and Mr.
                Siebenaller stated everything was fine and confirmed with me
                everything was in working order prior to the signing. There's a lot of
                desperate, disgusting people in this world and just like Mr.
                Siebenaller there's plenty of them.
              </p>
              <div className="container text-center d-grid gap-2 mx-auto m-5">
                <a
                  href="https://www.ohioattorneygeneral.gov/individuals-and-families/consumers/file-a-complaint.aspx"
                  target="blank"
                  className="btn btn-primary active"
                  type="button"
                  id="cc"
                  style={{ backgroundColor: "#0377fc !important" }}
                >
                  SUPPORT US BY FILING A REPORT
                </a>
              </div>
              <p>
                Just a few days later while driving in my home town in Tennessee
                several lights then popped up on my dash indicating other errors the
                vehicle started to register and later I would confirm with two
                dealerships more than likely the battery harness was disconnected from
                the vehicle and the onboard ECM control system that monitors these
                errors was reset which resulted in no errors showing during the test
                drive I took at Findlay. I would later contact the dealer directly to
                which Mr. Overly would fully denounce almost immediately upon
                accusation state that my claims were ‘ludicrous’ and what I was
                stating was made up. Mr. Overly then became very agitated as I
                discussed legal ramifications and on one call I later had with him he
                would accuse me of using explictives against him on that call stating
                ‘you were the one that cussed me out’ which was completely false and
                just another lie. Mr. Overly would later state he didn’t want to hear
                from me and he would wait to hear from my attorney. I had spoken with
                David Siebenaller and Brad Overly. Brad would later state after the
                sale took place ‘Well you signed an As-Is statement'. I replied
                ‘There's a difference sir between providing an AS-IS statement for a
                vehicle and selling a vehicle that you didn’t disclose had defects and
                selling it with an AS-IS.’ There was nothing but a long pause.
              </p>
              <p>
                As I mentioned car dealerships like Findlay Automotive use AS-IS
                agreements to get around legal ramifications or problems after a deal
                is completed. Findlay relies heavily on these types of documents
                because they purchase large amounts of used vehicles from auctions
                local and out of state just like mine and throw a huge price tag on
                the vehicle when it's not worth the asking price given it might have
                defects or has the need for general maintenance. Most of these
                vehicles have minor to major repairs that need to be done and even
                after a required inspection is complete Findlay puts a price tag on
                the car and says it's like new.
              </p>
              <p>
                Obviously a vehicle's price says a lot about the vehicle and who's
                selling it. Well the particular price for this vehicle was
                significantly lower at the time than other vehicles in its exact
                lineup and it also was a year newer which made it very enticing. I was
                later told ‘we have the best offer anywhere in the region no one else
                has an offer like we do so we can’t go lower’ Still concerned about
                the price I enquired about the vehicle several times and in fact
                through multiple short phone calls nothing was wrong with the vehicle.
                I was later told and quote ‘if there were any issues with the vehicle,
                the vehicle would be with our discounted salvaged vehicles.' The
                Federal Trade Commision has strict requirements around used vehicles
                sales and several class action lawsuits have been won given dealers
                push the bounds of these requirements and my situation is a perfect
                example of this. State officials handle things slightly different and
                in order to make an actual impact with a case like this it will
                require potential class-action constraints. Want to support me and
                help others too? Follow us on Twitter Click Here. Use the hashtag
                #SADF (Stop Auto Dealership Fraud) I’m looking to promote and post
                about other similar issues in the industry to raise awareness. Going
                and purchasing a vehicle from a car dealership you shouldn’t be
                worried about receiving a fair, honest transaction. Stand with me and
                stand against dealership theft and fraud.
              </p>
              <p>
                In conclusion, regardless of these events taken place it's about
                transparency and ethical standards in my opinion. Many dealerships use
                what is referred to as puffery. Puffery is used to denote the
                exaggerations reasonably to be expected of a seller as to the degree
                of quality of his product, the truth or falsity of which cannot be
                precisely determined. This is where things start to turn Gray and this
                is where Findlay and its associates make their big payouts and
                kickbacks.
              </p>
              <div className="container text-center mt-5 pt-5">
                <h3>1/5 Star Google Consumer Reviews Findlay</h3>
              </div>
              <div className="text-center">
                <a
                  href="https://www.google.com/search?q=findlay+chrystler&rlz=1C1ONGR_enUS989US989&oq=findlay+chrystler&aqs=chrome..69i57.5415j0j7&sourceid=chrome&ie=UTF-8#lrd=0x883ea97cbf957453:0x2f848ed6415761db,1,,,"
                  target="_blank"
                >
                  See more Findlay Chrystler Google Reviews
                </a>
              </div>
              <div className="container mt-5">
                <p>
                  These are perfect examples as to how Findlay does business with its
                  cutomers. Findlay is purchasing used auctioned vehicles that are
                  damaged knowing fully well what most of the issues are given they
                  run diagnostics as required by law.
                </p>{" "}
                <br />
                <p>
                  If I could rate less than 1 star I would, overall experience was
                  awful. I showed up right as they were about to close (called ahead)
                  with full intentions of purchasing a vehicle. As I arrived the sales
                  associate checked his phone for the time, sighed and asked if I
                  planned to purchase that day. After going through the approval
                  process and receiving my trade in value (lower than what they sent
                  online) I told them what I was looking for in terms of a monthly
                  payment. The sales associate brought out the rates (higher than what
                  I was looking for) . I asked if he was able to make a deal to get
                  the payment in my range ($70-80 difference), he got his manager BRAD
                  OVERLY. He was extremely unprofessional, rude, arrogant and an
                  overall a**hole.
                </p>
                <p>
                  Review from 11/23/2021 by:
                  <a
                    href="https://www.google.com/maps/contrib/104376085494983195119/place/ChIJU3SVv3ypPogR22FXQdaOhC8/@40.9007693,-84.5263313,7z/data=!4m6!1m5!8m4!1e1!2s104376085494983195119!3m1!1e1?hl=en-US"
                    target="_blank"
                  >
                    Dylan Blunk- Click To Read Review
                  </a>
                </p>
                <br />
                <p>
                  Nice and friendly up front. Paid $27,000 for a vehicle. The next day
                  several malfunctions occurred. Contacted the dealer no response at
                  first. After several angry calls and emails was told that a simple
                  malfunction that should have been noticed upon their reception of
                  the vehicle, would cost me $2,000 to fix. A separate issue that
                  would be done at any other dealership upon their inspection of
                  incoming vehicle was not noted and I am left holding the bill for
                  that one also. All in all whatever the advertise price is add
                  another $3,000 for things they cover up and refuse to negotiate
                  with. My $27,000 vehicle was inoperable and unreliable the day after
                  sale. So I will spend the $3,000 out of pocket and never shop there
                  again. Byer beware. Quote from the sales manager is we move 150
                  vehicles a month and don't have time to check everything. Again
                  friendly up front but do not care about customers.
                </p>
                <p>
                  Review from 2/15/2022 by:
                  <a
                    href="https://www.google.com/maps/contrib/117924675419850052541/place/ChIJU3SVv3ypPogR22FXQdaOhC8/@35.5139113,-89.0070042,6z/data=!4m6!1m5!8m4!1e1!2s117924675419850052541!3m1!1e1?hl=en-US"
                    target="_blank"
                  >
                    Joshua Iannantuono- Click To Read Review
                  </a>
                </p>
                <br />
                <p>
                  Do NOT buy your vehicle here!!! I recently discovered my parking
                  brake pedal on my 2016 F150 was being held up by cleverly hidden zip
                  ties. I then discovered that the actual parking brake cable isn’t
                  even connected to the brake caliper. Sooooo I called the dealership
                  and spoke with (I think) the assistant GM. I told him about my
                  issue. He said he would do some research and call me back, that day.
                  That was a Monday. Didn’t hear a word back that day…..or Tuesday. Or
                  Wednesday. In fact, all week. I had to call back the following
                  Monday. So I spoke with the GM and voiced my concerns. He said that
                  this wasn’t something that they would have done. He explained to me
                  that it is THEIR POLICY TO CONDUCT NO INSPECTION!!! This is what
                  their “inspection” consists of per the GM. Someone drives it up the
                  road. They look to make sure the tires are decent. That’s it!!!! He
                  said he’s never heard of a dealership that conducts any sort of
                  multipoint inspection. Take your business elsewhere.
                </p>
                <p>
                  Review from 3/07/2022 by:
                  <a
                    href="https://www.google.com/maps/contrib/110917699995212832115/place/ChIJU3SVv3ypPogR22FXQdaOhC8/@41.0586847,-83.682013,17z/data=!4m6!1m5!8m4!1e2!2s110917699995212832115!3m1!1e1?hl=en-US"
                    target="_blank"
                  >
                    Brian Wolfe- Click To Read Review
                  </a>
                </p>
                <br />
              </div>
              <div className="container text-center d-grid gap-2 mx-auto m-5">
                <a
                  href="https://www.ohioattorneygeneral.gov/individuals-and-families/consumers/file-a-complaint.aspx"
                  target="blank"
                  className="btn btn-primary active"
                  type="button"
                  id="cc"
                  style={{ backgroundColor: "#0377fc !important" }}
                >
                  SUPPORT US BY FILING A REPORT
                </a>
              </div>
            </div>
          </div>
         
        </div>
      </>
      
      
    );
    }
    
    export default Home;
    